<template>
  <div class="login-container">
    <div class="login-content">
      <div style="color:white;background-color: #1776E1;padding: 20px 20px;box-sizing: border-box;">FBLA 登录</div>
      <!-- <div style="color:white;background-color: #1776E1;padding: 20px 20px;box-sizing: border-box;">Envirothon 登录</div> -->
      <el-form style="padding: 10px 20px;box-sizing: border-box" label-position="top" :model="loginInfo" :rules="rules"
               ref="loginForm">
        <el-form-item label="账户邮箱" prop="username">
          <el-input v-model="loginInfo.username" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="账户密码" prop="password">
          <el-input v-model="loginInfo.password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="项目">
          <el-select v-model="project_code" style="width: 100%">
            <el-option v-for="(project,index) in projects" :key="index" :label="project.project_name"
                       :value="project.project_code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录类型" prop="loginType">
          <el-select v-model="loginInfo.loginType" style="width: 100%">
            <el-option value="student">student</el-option>
            <el-option value="adviser">adviser</el-option>
            <el-option value="coordinator">coordinator</el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="red--text">{{ errorMessage }}</div>
      <div style="padding: 0px 20px 20px 20px;box-sizing: border-box">
        <p class="btn-login" @click="doSubmit">登录</p>
      </div>
    </div>
  </div>
</template>

<script>
import {loginByStudentService, loginByContactService} from "@/api/user";
import {getLoginProjects} from '../api/project'
import {setProjectCode, setUserId, setUserType} from "@/utils/store"

export default {
  name: "App",
  data() {
    return {
      projects: [],
      open_id: this.$route.query.open_id,
      loginInfo: {
        username: "",
        password: "",
        loginType: "",
      },
      errorMessage: "",
      project_code: "",
      rules: {
        username: [
          {required: true, message: '请输入邮箱', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        loginType: [
          {required: true, message: '请选择登录类型', trigger: 'blur'}
        ]
      },
      errMsg: "",
      alert: false,
    };
  },
  mounted() {
    console.log('111')
    if (this.open_id == undefined || this.open_id == null || this.open_id == '') {
      this.$message.error('参数有误！')
      return
    }
    this.requestProjects()
  },
  methods: {
    doSubmit() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          if (this.project_code == '') {
            this.$message.error('请选择项目')
            return
          }
          switch (this.loginInfo.loginType) {
            case "student":
              this.handleStudentLogin();
              return;
            case "adviser":
              this.handleContactLogin();
              return;
            case "coordinator":
              this.handleContactLogin();
              return;
          }
        }
      })
    },
    handleStudentLogin() {
      loginByStudentService({
        student_email: this.loginInfo.username,
        student_pwd: this.loginInfo.password,
        project_code: this.project_code,
        open_id: this.open_id,
      }).then((res) => {
        let result = res.data
        if (result.code === 0) {
          setUserId(result.data._id.$id)
          setUserType(this.loginInfo.loginType)
          setProjectCode(this.project_code)
          this.$router.push({path: "/projects"});
        } else {
          this.errorMessage = result.msg
        }
      })
    },
    handleContactLogin() {
      loginByContactService({
        contact_email: this.loginInfo.username,
        contact_pwd: this.loginInfo.password,
        project_code: this.project_code,
        open_id: this.open_id,
      }).then((res) => {
        let result = res.data
        if (result.code === 0) {
          setUserId(result.data._id.$id)
          setUserType(this.loginInfo.loginType)
          setProjectCode(this.project_code)
          this.$router.push({path: "/projects"});
        } else {
          this.errorMessage = result.msg
        }
      })
    },
    requestProjects() {
      getLoginProjects().then((res) => {
        this.projects = res.data.data
      })
    },
  }
};
</script>


<style scoped>

.btn-login {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  width: 120px;
  background-color: #1776E1;
  height: 42px;
  border-radius: 6px 6px 6px 6px;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.login-container {
  padding: 20px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.login-content {
  width: 100%;
  background-color: white;
  box-shadow: 0 25px 70px -10px rgba(0, 0, 0, 0.25);
}

</style>
